<template>
  <div>
    <div class="fixed inset-y-0 flex w-72 flex-col">
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-cyan-950 px-6"
      >
        <div class="flex h-16 shrink-0 items-center">
          <SparklesIcon class="h-8 w-8 text-gray-50" />
        </div>

        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col divide-y divide-cyan-900">
            <li>
              <ul role="list" class="my-2 -mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink
                    v-if="!item.children"
                    :to="item.to"
                    :class="[
                      (
                        item.exact
                          ? route.path === item.to
                          : route.path.startsWith(item.to)
                      )
                        ? 'bg-cyan-900 text-amber-50'
                        : 'hover:bg-cyan-900 text-orange-50',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        item.exact
                          ? route.path === item.to
                          : route.path.startsWith(item.to)
                            ? 'text-amber-50'
                            : 'text-orange-50',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </NuxtLink>

                  <Disclosure
                    v-else
                    v-slot="{ open }"
                    as="div"
                    :default-open="
                      route.path.startsWith(item.to) ||
                      item.children.some((subItem) =>
                        route.path.startsWith(subItem.to),
                      )
                    "
                  >
                    <DisclosureButton
                      :class="[
                        route.path.startsWith(item.to)
                          ? 'bg-cyan-900 text-amber-50'
                          : 'hover:bg-cyan-900 text-orange-50',
                        'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                          route.path.startsWith(item.to)
                            ? 'text-amber-50'
                            : 'text-orange-50',
                          'h-6 w-6 shrink-0',
                        ]"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                      <ChevronRightIcon
                        :class="[
                          open ? 'rotate-90' : '',
                          'ml-auto h-5 w-5 shrink-0 text-orange-50',
                        ]"
                        aria-hidden="true"
                      />
                    </DisclosureButton>

                    <DisclosurePanel as="ul" class="mt-1 px-2">
                      <li v-for="subItem in item.children" :key="subItem.name">
                        <DisclosureButton
                          :as="NuxtLink"
                          :to="subItem.to"
                          :class="[
                            route.path.startsWith(subItem.to)
                              ? 'bg-cyan-900 text-amber-50'
                              : 'hover:bg-cyan-900 text-orange-50',
                            'block rounded-md py-2 pr-2 pl-9 text-sm leading-6',
                          ]"
                          >{{ subItem.name }}
                        </DisclosureButton>
                      </li>
                    </DisclosurePanel>
                  </Disclosure>
                </li>
              </ul>
            </li>

            <li>
              <ul role="list" class="my-2 -mx-2 space-y-1">
                <li>
                  <Disclosure v-slot="{ open }" as="div">
                    <DisclosureButton
                      class="flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold hover:bg-cyan-900 text-orange-50"
                    >
                      <component
                        :is="UserIcon"
                        class="h-6 w-6 shrink-0 text-orange-50"
                        aria-hidden="true"
                      />
                      {{ auth.profile.attributes.name }}
                      <ChevronRightIcon
                        :class="[
                          open ? 'rotate-90' : '',
                          'ml-auto h-5 w-5 shrink-0 text-orange-50',
                        ]"
                        aria-hidden="true"
                      />
                    </DisclosureButton>

                    <DisclosurePanel as="ul" class="mt-1 px-2">
                      <li>
                        <DisclosureButton
                          as="a"
                          class="cursor-pointer block rounded-md py-2 pr-2 pl-9 text-sm leading-6 hover:bg-cyan-900 text-orange-50"
                          @click="auth.logout"
                        >
                          {{ $t("Sign Out") }}
                        </DisclosureButton>
                      </li>
                    </DisclosurePanel>
                  </Disclosure>
                </li>

                <li
                  class="text-orange-50 text-xs pt-4 flex items-center justify-center"
                >
                  <SparklesIcon class="h-4 w-4 mr-1" /> Admin v{{
                    runtimeConfig.public.appVersion
                  }}
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <main class="py-10 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>

    <div
      aria-live="assertive"
      class="z-20 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <transition-group
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <UINotification
            v-for="notification in notifications.notifications"
            :key="notification.id"
            :headline="notification.headline"
            :body="notification.body"
            :level="notification.level"
            @close="notifications.remove(notification)"
          />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuth } from "~/stores/auth.js";

useHead({
  htmlAttrs: {
    class: "h-full bg-gray-50",
  },
  bodyAttrs: {
    class: "h-full",
  },
  titleTemplate: "%s - Ultrafedt POS Admin",
});

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import {
  HomeIcon,
  BuildingOfficeIcon,
  UsersIcon,
  SparklesIcon,
  BoltIcon,
  UserIcon,
  EnvelopeIcon,
} from "@heroicons/vue/24/outline";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { UINotification } from "@ultrafedt/components";

const auth = useAuth();
const route = useRoute();
const notifications = useNotifications();
const NuxtLink = resolveComponent("nuxt-link");
const runtimeConfig = useRuntimeConfig();

const navigation = [
  { name: "Dashboard", to: "/", icon: HomeIcon, exact: true },
  {
    name: "Merchants",
    to: "/merchants",
    icon: BuildingOfficeIcon,
    exact: false,
  },
  { name: "Mail", to: "/mail", icon: EnvelopeIcon, exact: false },
  { name: "Users", to: "/users", icon: UsersIcon, exact: false },
  {
    name: "Connections",
    to: "/connection-drivers",
    icon: BoltIcon,
    exact: false,
  },
];
</script>
